import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('#allEvents').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" id="pAll" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-green"></i></button>',
        nextArrow: '<button aria-label="Next" id="nAll" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-green"></i></button>',
        appendArrows: $('.m-events-arrows'),
        draggable: true,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.m-events-button').on('click', function(){
        var eventDate = $(this).data('event');
        
        $('#allEvents').slick('slickUnfilter');

        if(eventDate === 'allButton') {
            $('.m-events-card').show();
        } else {
            $('.m-events-card').each(function() {
                var cardEventDate = $(this).data('event');

                if (cardEventDate !== eventDate) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });

            $('#allEvents').slick('slickFilter', '[data-event="' + eventDate + '"]');
        };

        const $allSlider = $('#allEvents');
        const slick = $allSlider.slick('getSlick');
        setTimeout(function() {
            if (slick.slideCount <= slick.options.slidesToShow) {
                $allSlider.find('.slick-slide').addClass('-active');
            } else {
                $allSlider.find('.slick-slide').removeClass('-active');
            }
        }, 100);

        if(slick.slideCount <= 0) {
            $('.m-events-none').show();
        } else {
            $('.m-events-none').hide();
        }
    });
});