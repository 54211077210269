import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(window).scroll(function() {
        var scroll = $(this).scrollTop();
        if (scroll >= 100) {
            $("#header").addClass("-scroll");
        } else {
            $("#header").removeClass("-scroll");
        }
    });

    $("#searchButton").on("click", function(){
        $("#searchform").toggleClass("-show");
    });

    $("#dropdownButton").on("click", function(){
        $(".header-button-bars").toggle();
        $(".header-button-x").toggle();
        $("#fixedNav").toggleClass('-invisible');
        $(".header-book").toggleClass('-invisible');
        $(".header-search").toggleClass('-invisible');
        $("#dropdownNav").slideToggle();
        $("#header").toggleClass("-dropdown-bg");
    });
});