import { default as $ } from 'jquery';

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new Mmenu("#mobileMenu", {
            navbar: {
                title: "Menu",
            },
            setSelected: {
                "hover": true,
            },
            offCanvas: {
                position: "left-front"
            }
        },
        {
            offCanvas: {
                clone: false,
                page: {
                    selector: "#page",
                }
            }
        });
    
        const api = menu.API;
        var open = false;

        var openMenu = function(){
            jQuery('body, #header').addClass('-navActive');
            jQuery("#mobileNavButtonIcon").toggleClass('fa-x').toggleClass('fa-bars');
            api.open();
            open = true;
        };

        var closeMenu = function(){
            jQuery('body, #header').removeClass('-navActive');
            jQuery("#mobileNavButtonIcon").toggleClass('fa-x').toggleClass('fa-bars');
            api.close();
            open = false;
        };

        jQuery("#mobileNavButton").click(function (event){
            event.stopPropagation();
            var toggle = open ? closeMenu : openMenu;
            toggle();
        });

        jQuery(".mm-slideout").click(function(event){
            closeMenu();
            jQuery("#mobileNavButtonIcon").removeClass('fa-x').addClass('fa-bars');
        });
    }
);