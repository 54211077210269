// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './carousel/carousel';
import './splitting/splitting';
import './carousel/carousel-spaces';
import './carousel/carousel-offers';
import './events/events';
import './explore/explore';
import './carousel/carousel-center';
import './postlist/post-list';
import './testimonials/testimonials';
import './dropdown/dropdown';
import './joblist/job-list';
import './banner/banner';
import './gallery/gallery';
import './packages/packages';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
