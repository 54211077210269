import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-jobs-button").on('change', function(){
        var jobtype = $('.m-jobs-button.-jobtype').find(':selected').data('job');
        var jobsector = $('.m-jobs-button.-jobsector').find(':selected').data('sector');

        if(jobsector === 'all') {
            $(".m-jobs-job").each(function() {
                var job = $(this).data('job');
                if(job === jobtype || jobtype === 'all') {
                    $(this).show();
                } else {
                    $(this).hide();
                };
            });
        } else {
            $(".m-jobs-job").each(function() {
                var job = $(this).data('job');
                if($(this).hasClass(jobsector) && (jobtype === job || jobtype === 'all')) {
                    $(this).show();
                } else {
                    $(this).hide();
                };
            });
        };
   });
});