import { default as $ } from 'jquery';

$(window).on("load",function() {
   var loadvalue = $(".m-accordion-card-dropdown").find(":selected").val();
   showContent(loadvalue);
});

$(document).on('DOMContentLoaded', function() {
   $(".m-accordion-card-dropdown").on('change', function(){
      var selected = this.value;
      showContent(selected);
   });
});

function showContent(value) {
   var selected = value;

   $('.m-accordion-content').each(function(){
      var section = $(this).data('content');
      
      if(selected == section) {
         $('.m-accordion-content').removeClass('-show');
         $(this).addClass('-show');
      };
   });
}