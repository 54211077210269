import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('.m-packages-button').on('click', function(){
        var packageButton = $(this).data('package');

        if(packageButton === 'allButton') {
            $('.m-packages-card').show();
        } else {
            $('.m-packages-card').each(function() {
                var cardPackage = $(this).data('package');

                if (cardPackage !== packageButton) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        };
    });
});