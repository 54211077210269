import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('.m-list-button').on('click', function(){
        var eventDate = $(this).data('time');
        
        if(eventDate === 'allButton') {
            $('.m-list-card-container').show();

        } else {
            $('.m-list-card-container').each(function() {
                var cardEventDate = $(this).data('time');

                if (cardEventDate !== eventDate) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        };
    });
});