import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-gallery-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-green"></i></button>',
        nextArrow: '<button aria-label="Next" aria-label="Next" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-green"></i></button>',
        appendArrows: $('.m-gallery-arrows'),
        draggable: false,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: false,
        infinite: true,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 1,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 578,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});