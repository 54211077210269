import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load", function() {
  $(window).on("scroll", function() {
    var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();

    $(".addAnimation").each(function() {
        var objectBottom = $(this).offset().top;

        if (objectBottom < windowBottom) {
            $(this).addClass('m-fadeIn');
        } else {
            $(this).removeClass('m-fadeIn');
        }
    });
  }).trigger('scroll');
});