import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-spaces-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev me-3 mg-lg-4"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-white"></i></button>',
        nextArrow: '<button aria-label="Next" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-white"></i></button>',
        appendArrows: $('.m-carousel-spaces-arrows'),
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1750,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3.2,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});