import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-offers-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev me-5 me-lg-4"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-green"></i></button>',
        nextArrow: '<button aria-label="Next" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-green"></i></button>',
        appendArrows: $('.m-carousel-offers-arrows'),
        draggable: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});