import { default as $ } from 'jquery';

$(window).on("load",function() {
    $('.m-explore-card:first').slideDown();
    var pin = "#pin" + $('.m-explore-card:first').data('number');
    var line = "#linesvg" + $('.m-explore-card:first').data('number');
    $(pin).addClass('-show');
    $(line).addClass('-show');
   
    $('.m-explore-pin').on('click', function(){
        $('.m-explore-pin').removeClass('-show');
        $(this).addClass('-show');
        var card = $(this).data('number');
        $('.m-explore-card').hide();
        $('#card' + card).slideDown();
        $('.m-explore-pin-line').removeClass('-show');
        $('#linesvg' + card).addClass('-show');
    });
    
    explorePins();

    $(window).scroll(function () {
        explorePins();
    });
    
    $(window).on('resize', function(){
        explorePins();
    });
});

// Function to start autoplay when container is in view
function explorePins() {
    var container = $('#exploreBlock');

    if (!container.length) {
        return; // Exit the function if the container is not found
    }

    var containerTop = container.offset().top;
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    if (containerTop < windowBottom) {
        var cards = $('#exploreCards');
        var cardPos = cards.position();
        var cardLeft = cardPos.left;
        
        if ($(window).width() < 1400) {
            $('.-line').attr('x2', cardLeft).attr('y2', 150);  
        } else {
            $('.-line').attr('x2', cardLeft).attr('y2', 250);
        };
        
        $(".m-explore-pin").each(function() {
            var position = $(this).position();
            var topPosition = position.top;
            var leftPosition = position.left;
            var line = "#line" + $(this).data('number');
        
            $(line).attr('x1', leftPosition).attr('y1', topPosition);
        });
        
    }
}